<template lang="pug">
.blog-card.d-flex.flex-column.position-relative.bg-white.text-black.shadow-below-blur.hover-scale-img.cursor-pointer(
  @click="navigateTo(`${blogUrl}/${post.url}`)"
)
  .max-h-px-400
    .aspect-ratio__4-4.position-relative
      ResponsiveImage.position-absolute.w-100.h-100.top-0.max-h-px-400(
        :image="post.hero_image_cloudinary",
        :alt="post.title",
        cover
      )
  .px-3.d-flex.blog-card__header
    .d-flex.flex-column.align-start.h-100.pt-2
      .h5.mb-0 {{ post.title }}
    fa(
      icon="fa-chevron-right",
      size="2xl",
      class="text-blue"

    )
</template>

<script setup>
const { urls: localeURLs } = useLocale()
const blogUrl = localeURLs.blog

defineProps({
  post: {
    type: Object,
    default: () => {}
  }
})
</script>

<style lang="scss" scoped>
.blog-card {
  &__header {
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .h5 {
      font-size: 0.95rem;
      @media (min-width: $notebook) {
        font-size: 1rem;
      }
    }
  }
}
</style>
